import PropTypes from 'prop-types';
import React from 'react';

import RenderContentPageComponent from '@glass/rock/components/ContentPage/RenderContentPageComponent';

import {
  HERO_CAROUSEL_SECTION_ID,
  HOW_WE_GET_YOU_HIRED_SECTION_ID,
} from '@glass/web/modules/content/pages/contentIds';

import withContentAssignmentsContext from '@glass/shared/components/App/withContentAssignmentsContext';
import AutoOgImageHead from '@glass/shared/components/Head/AutoOgImageHead';
import JsonLDHead from '@glass/shared/components/Head/JsonLDHead';
import ConsumerLayoutPage from '@glass/shared/components/Layout/ConsumerLayoutPage';
import { DISABLE_LOADING } from '@glass/shared/components/Section/loadingMethods';
import usePresentation from '@glass/shared/components/hooks/usePresentation';

const PRESENTATION_SECTIONS = [
  {
    type: HERO_CAROUSEL_SECTION_ID,
    ssr: true,
  },
];

const footerProps = {
  resourceSectionLoadingMethod: DISABLE_LOADING,
  supportSectionLoadingMethod: DISABLE_LOADING,
};

// this is so apollo fetches the data
const DEFAULT_SECTIONS = [
  ...PRESENTATION_SECTIONS,
  {
    type: HOW_WE_GET_YOU_HIRED_SECTION_ID,
  },
];

// todo lazy load below fold sections after primary assets are loaded or in view
function ContentPage({ pageData }) {
  const {
    sections: sectionsData = DEFAULT_SECTIONS,
    jsonLdSchemas,
    pageTitle,
    canonicalPath,
    description,
  } = pageData || {};

  const isPresentation = usePresentation();

  const sections = isPresentation ? PRESENTATION_SECTIONS : sectionsData;

  return (
    <ConsumerLayoutPage
      canonicalPath={canonicalPath}
      description={description}
      footerProps={footerProps}
      pageTitle={pageTitle}
    >
      {jsonLdSchemas?.map((jsonLdSchema) => (
        <JsonLDHead key={jsonLdSchema['@type']} schema={jsonLdSchema} />
      ))}
      <AutoOgImageHead />
      {sections.map((sectionData) => {
        const { type } = sectionData;
        return <RenderContentPageComponent key={type} data={sectionData} type={type} />;
      })}
    </ConsumerLayoutPage>
  );
}

ContentPage.propTypes = {
  pageData: PropTypes.shape({
    canonicalPath: PropTypes.string,
    jsonLdSchemas: PropTypes.arrayOf(
      PropTypes.shape({
        '@type': PropTypes.string,
      }),
    ),
    pageTitle: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      }),
    ),
  }),
};

ContentPage.displayName = 'ContentPage';

export default React.memo(withContentAssignmentsContext(ContentPage));
