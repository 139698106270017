import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import JsonLDScript from '@glass/shared/components/Head/JsonLDScript';

function JsonLDHead({ schema }) {
  if (!schema) {
    return null;
  }

  return (
    <Head>
      <JsonLDScript schema={schema} />
    </Head>
  );
}

JsonLDHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
};

export default React.memo(JsonLDHead);
