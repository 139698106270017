import seedrandom from 'seedrandom';

function isArray(obj: any) {
  return Array.isArray(obj);
}

function seedify(seed: any) {
  if (
    /(number|string)/i.test(
      Object.prototype.toString.call(seed).match(/^\[object (.*)\]$/)?.[1] || '',
    )
  )
    return seed;

  if (Number.isNaN(seed)) {
    const result = Number(
      String(seed)
        .split('')
        .map((x) => x.charCodeAt(0))
        .join(''),
    );
    return result;
  }
  return seed;
}

function seedRand(func: () => number, min: number, max: number) {
  return Math.floor(func() * (max - min + 1)) + min;
}

const shuffle = function <T>(arr: Array<T>, rawSeed: any): Array<T> | null {
  if (!isArray(arr)) return null;
  const seed = seedify(rawSeed) || 'none';

  const size = arr.length;
  const rng = seedrandom(seed);
  const resp = [];
  const keys = [];

  for (let i = 0; i < size; i += 1) {
    keys.push(i);
  }
  for (let i = 0; i < size; i += 1) {
    const r = seedRand(rng, 0, keys.length - 1);
    const g = keys[r];
    keys.splice(r, 1);
    resp.push(arr[g]);
  }
  return resp;
};

const unshuffle = function <T>(arr: Array<T>, rawSeed: any): Array<T> | null {
  if (!isArray(arr)) return null;
  const seed = seedify(rawSeed) || 'none';

  const size = arr.length;
  const rng = seedrandom(seed);
  const resp = [];
  const keys = [];

  for (let i = 0; i < size; i += 1) {
    resp.push(null);
    keys.push(i);
  }

  for (let i = 0; i < size; i += 1) {
    const r = seedRand(rng, 0, keys.length - 1);
    const g = keys[r];
    keys.splice(r, 1);
    resp[g] = arr[i];
  }

  return resp as Array<T>;
};

const self = {
  shuffle,
  unshuffle,
};

export default self;
