import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@glass/web/components/base/Unstable_Grid2';
import { HOW_WE_GET_YOU_HIRED_SECTION_ID } from '@glass/web/modules/content/pages/contentIds';

import { DEFAULT_HWGYH_SECTION_ORDER } from '@glass/shared/components/Section/HowWeGetYouHiredSection/HWGYHSections';
import SectionItem from '@glass/shared/components/Section/HowWeGetYouHiredSection/SectionItem';
import Section from '@glass/shared/components/Section/Section';
import SectionSpacer from '@glass/shared/components/Section/SectionSpacer';
import usePageTerms from '@glass/shared/modules/content/staticVariations/usePageTerms';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const HowWeGetYouHiredSection = React.forwardRef(
  ({ title, subtitle, isAlternatingColor, type }, ref) => {
    const { pageTerm } = usePageTerms();

    return (
      <Section
        ref={ref}
        ctaText="Get hired faster"
        isAlternatingColor={isAlternatingColor}
        subtitle={
          subtitle ||
          `Everything you need to $(verbWords.singular) your${
            pageTerm ? ` <em>${pageTerm}</em>` : ''
          } $(resumeWords.singular), <b>in one place</b>`
        }
        title={title}
        type={type}
      >
        <SectionSpacer>
          <Grid container spacing={1}>
            {DEFAULT_HWGYH_SECTION_ORDER.map((sectionId) => (
              <Grid key={sectionId} md={3} sm={6} xs={12}>
                <SectionItem sectionId={sectionId} />
              </Grid>
            ))}
          </Grid>
        </SectionSpacer>
      </Section>
    );
  },
);

HowWeGetYouHiredSection.defaultProps = {
  title: `$(brand.title) helps you get <b>hired</b> faster`,
};

HowWeGetYouHiredSection.propTypes = {
  isAlternatingColor: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default React.memo(
  withTrackingContext(HowWeGetYouHiredSection, HOW_WE_GET_YOU_HIRED_SECTION_ID),
);
