import PropTypes from 'prop-types';
import React from 'react';

import Card from '@glass/web/components/base/Card';
import CardActionArea from '@glass/web/components/base/CardActionArea';
import CardContent from '@glass/web/components/base/CardContent';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import SubSection from '@glass/shared/components/Section/SubSection';

const useStyles = makeStyles()((theme, { bgcolor }) => ({
  root: {
    height: '100%',
    backgroundColor: bgcolor,
    color: bgcolor && theme.palette.getContrastText(bgcolor),
    transition: theme.transitions.create(['background', 'font']),
  },
  content: {
    height: 'inherit',
    display: FLEX,
    flexDirection: 'column',
  },
  icon: {
    margin: theme.spacing(1.5, 'auto', 2),
  },
  actionArea: {
    height: 'inherit',
    textDecoration: 'none!important',
  },
}));

function CardSubSection({
  component,
  title,
  subtitle,
  align,
  icon,
  bgcolor,
  trackProps,
  params,
  variant,
}) {
  const { classes } = useStyles({ bgcolor });
  return (
    <Card className={classes.root} variant={variant}>
      <CardActionArea
        className={classes.actionArea}
        component={component}
        params={params}
        trackProps={trackProps}
      >
        <CardContent className={classes.content}>
          <SubSection align={align} icon={icon} subtitle={subtitle} title={title} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardSubSection.propTypes = {
  align: PropTypes.string,
  bgcolor: PropTypes.string,
  component: PropTypes.elementType,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  variant: PropTypes.string,
};

export default CardSubSection;
