import PropTypes from 'prop-types';
import React from 'react';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import keyframes from '@glass/web/modules/theme/tssKeyframes';

const useStyles = makeStyles()({
  '@keyframes blinking-cursor': {},
  cursor: {
    animation: `${keyframes`
    0% {
      opacity: 0;
    },
    50% {
      opacity: 1;
    },
    100% {
      opacity: 0;
    }
    `} 1s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite`,
  },
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  longestWord: {
    opacity: 0,
  },
  rotatingContainer: {
    left: 0,
  },
  word: {
    position: 'absolute',
    left: 0,
  },
});

const getLongestWord = (words) =>
  words.reduce((longestWord, word) => (word.length > longestWord.length ? word : longestWord), '');

// todo: use true width
// const getTextWidth = (getTextWidth = (text, font) => {
//   if ()
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');
//   context.font = font || getComputedStyle(document.body).font;
//   return context.measureText(text).width;
// });

class ReactRotatingText extends React.Component {
  constructor(props) {
    super(props);
    const { items } = this.props;
    const index = 0;
    this.state = {
      index,
      output: items[index] || '',
      longestWord: getLongestWord(items),
    };
    this.timeouts = [];
  }

  componentDidMount() {
    const { isStatic } = this.props;
    if (!isStatic) {
      this.animate.bind(this)(); // begin the animation loop
    }
  }

  componentDidUpdate(
    { items, isStatic },
    { longestWord: prevLongestWord, isStatic: prevIsStatic },
  ) {
    const longestWord = getLongestWord(items);
    if (prevLongestWord !== longestWord) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        longestWord,
      });
    }
    if (isStatic && !prevIsStatic) {
      this.animate.bind(this)();
    }
  }

  componentWillUnmount() {
    this.timeouts.forEach((x) => clearTimeout(x)); // stop all the loops
  }

  loop(loopingFunc, pause) {
    // save the timeouts so we can stop on unmount
    const timeout = setTimeout(loopingFunc, pause);
    this.timeouts.push(timeout);

    // prevent memory leak
    const maxTimeouts = 10;
    if (this.timeouts.length > maxTimeouts) {
      clearTimeout(this.timeouts[0]);
      this.timeouts.shift();
    }
  }

  type(text, callback) {
    const { output } = this.state;
    const { typingInterval, onTypingEnd } = this.props;
    const loopingFunc = this.type.bind(this, text, callback);
    const word = text.split('');

    // set the string one character longer
    this.setState({ output: word.slice(0, output.length + 1).join('') });

    // if we're still not done, recursively loop again
    if (output.length < word.length) {
      this.loop(loopingFunc, typingInterval);
    } else {
      if (typeof onTypingEnd === 'function') {
        onTypingEnd();
      }
      callback();
    }
  }

  erase(callback) {
    const { output } = this.state;
    const { deletingInterval, onDeletingEnd, onDeletingStart } = this.props;
    const loopingFunc = this.erase.bind(this, callback);
    const word = output.split('');

    if (typeof onDeletingStart === 'function') {
      onDeletingStart();
    }
    // set the string one character shorter
    this.setState({ output: word.slice(0, word.length - 1).join('') });

    // if we're still not done, recursively loop again
    if (word.length !== 0) {
      this.loop(loopingFunc, deletingInterval);
    } else {
      if (typeof onDeletingEnd === 'function') {
        onDeletingEnd();
      }
      callback();
    }
  }

  animate() {
    const { index } = this.state;
    const { items, pause, emptyPause, onTypingStart, isStatic } = this.props;
    if (isStatic) {
      return;
    }
    const { type } = this;
    const { erase } = this;
    const loopingFunc = this.animate.bind(this);
    const nextIndex = index === items.length - 1 ? 0 : index + 1;

    const nextWord = () => {
      this.setState({ index: nextIndex });
      this.loop(loopingFunc, emptyPause);
    };

    if (typeof onTypingStart === 'function') {
      onTypingStart();
    }

    type.bind(this)(items[index], () => {
      this.loop(erase.bind(this, nextWord), pause);
    });
  }

  render() {
    const { cursor, items, isStatic, className, classes, cx } = this.props;
    const { index, output, longestWord } = this.state;

    return (
      <span aria-label={isStatic ? items[0] : items[index]} className={cx(className, classes.root)}>
        <span aria-label="hidden" className={classes.rotatingContainer}>
          <span aria-label="hidden" className={classes.word}>
            {isStatic ? items[0] : output}
            {cursor ? (
              <span aria-label="hidden" className={classes.cursor}>
                |
              </span>
            ) : null}
          </span>
          <span aria-label="hidden" className={classes.longestWord}>
            {isStatic ? items[0] : longestWord}
          </span>
        </span>
      </span>
    );
  }
}

ReactRotatingText.propTypes = {
  classes: PropTypes.shape({
    '@keyframes blinking-cursor': PropTypes.string,
    cursor: PropTypes.string,
    longestWord: PropTypes.string,
    root: PropTypes.string,
    rotatingContainer: PropTypes.string,
    word: PropTypes.string,
  }),
  className: PropTypes.string,
  cursor: PropTypes.bool,
  cx: PropTypes.func,
  deletingInterval: PropTypes.number,
  emptyPause: PropTypes.number,
  isStatic: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  onDeletingEnd: PropTypes.func,
  onDeletingStart: PropTypes.func,
  onTypingEnd: PropTypes.func,
  onTypingStart: PropTypes.func,
  pause: PropTypes.number,
  typingInterval: PropTypes.number,
};

ReactRotatingText.defaultProps = {
  cursor: true,
  deletingInterval: 50,
  emptyPause: ONE_SEC_MS,
  items: [],
  pause: 3 * ONE_SEC_MS,
  typingInterval: 50,
  isStatic: false,
};

// eslint-disable-next-line react/no-multi-comp
function ReactRotatingTextWithStyles(props) {
  const { classes, cx } = useStyles();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReactRotatingText {...props} classes={classes} cx={cx} />;
}

export default ReactRotatingTextWithStyles;
