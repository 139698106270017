import dynamic from 'next/dynamic';

import {
  CAROUSEL_SECTION_ID,
  CUSTOM_TEMPLATE_SECTION_ID,
  EDITABLE_RESUME_SECTION_ID,
  EDUCATION_EXPERIENCE_SECTION_ID,
  HERO_CAROUSEL_SECTION_ID,
  HERO_SEARCH_SECTION_ID,
  HOW_WE_GET_YOU_HIRED_SECTION_ID,
  INDUSTRIES_SECTION_ID,
  JOB_TITLES_SECTION_ID,
  RANDOM_RESUME_SECTION_ID,
  RELATED_JOB_TITLES_SECTION_ID,
  RELATED_RESOURCES_SECTION_ID,
  RESOURCES_SECTION_ID,
  RESUME_EXAMPLES_SECTION_ID,
  SALARY_SECTION_ID,
  SUPPORT_SECTION_ID,
  TESTIMONIAL_SECTION_ID,
  WORK_EXPERIENCE_SECTION_ID,
} from '@glass/web/modules/content/pages/contentIds';

import HeroCarouselSection from '@glass/shared/components/Section/HeroSection/HeroCarouselSection';
import HeroSearchSection from '@glass/shared/components/Section/HeroSection/HeroSearchSection';
import HowWeGetYouHiredSection from '@glass/shared/components/Section/HowWeGetYouHiredSection/HowWeGetYouHiredSection';
import LoadingSection from '@glass/shared/components/Section/LoadingSection';

const DynamicCarouselSection = dynamic(
  () => import('@glass/shared/components/Section/CarouselSection/CarouselSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicCustomTemplateSection = dynamic(
  () => import('@glass/shared/components/Section/CustomTemplateSection/CustomTemplateSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicRandomResumeSection = dynamic(
  () => import('@glass/shared/components/Section/RandomResumeSection/RandomResumeSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicEditableResumeSection = dynamic(
  () => import('@glass/shared/components/Section/EditableResumeSection/EditableResumeSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicWorkExperienceSection = dynamic(
  () => import('@glass/shared/components/Section/ExperienceSection/WorkExperienceSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicEducationExperienceSection = dynamic(
  () => import('@glass/shared/components/Section/ExperienceSection/EducationExperienceSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicIndustriesSection = dynamic(
  () => import('@glass/shared/components/Section/IndustriesSection/IndustriesSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);
const DynamicJobTitlesSection = dynamic(
  () => import('@glass/shared/components/Section/JobTitlesSection/JobTitlesSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);

const DynamicTestimonialSection = dynamic(
  () => import('@glass/shared/components/Section/TestimonialSection/TestimonialSection'),
  {
    ssr: false,
    loading: LoadingSection,
  },
);

// will sometimes be empty so no loading section
const DynamicSalarySection = dynamic(
  () => import('@glass/shared/components/Section/SalarySection/SalarySection'),
  {
    ssr: false,
  },
);
export const DynamicResourcesSection = dynamic(
  () => import('@glass/shared/components/Section/ResourcesSection/ResourcesSection'),
  {
    ssr: false,
  },
);

// will sometimes be empty so no loading section
const DynamicRelatedResourcesSection = dynamic(
  () => import('@glass/shared/components/Section/ResourcesSection/ResourcesSection'),
  {
    ssr: false,
    // loading: LoadingSection, // these sections are less likely to be rendered so no loading for them
  },
);

export const DynamicSupportSection = dynamic(
  () => import('@glass/shared/components/Section/SupportSection/SupportSection'),
  {
    ssr: false,
    // loading: LoadingSection, // these sections are less likely to be rendered so no loading for them
  },
);

const DynamicResumeExamplesSection = dynamic(
  () => import('@glass/shared/components/Section/ResumeExamplesSection/ResumeExamplesSection'),
  {
    ssr: false,
  },
);

// todo: add an SSR component
const CONTENT_PAGE_SECTIONS = {
  [HERO_CAROUSEL_SECTION_ID]: {
    Component: HeroCarouselSection,
  },
  [HERO_SEARCH_SECTION_ID]: {
    Component: HeroSearchSection,
  },
  [HOW_WE_GET_YOU_HIRED_SECTION_ID]: {
    Component: HowWeGetYouHiredSection,
  },
  [CAROUSEL_SECTION_ID]: {
    Component: DynamicCarouselSection,
  },
  [CUSTOM_TEMPLATE_SECTION_ID]: {
    Component: DynamicCustomTemplateSection,
  },
  [RANDOM_RESUME_SECTION_ID]: {
    Component: DynamicRandomResumeSection,
  },
  [EDITABLE_RESUME_SECTION_ID]: {
    Component: DynamicEditableResumeSection,
  },
  [WORK_EXPERIENCE_SECTION_ID]: {
    Component: DynamicWorkExperienceSection,
  },
  [EDUCATION_EXPERIENCE_SECTION_ID]: {
    Component: DynamicEducationExperienceSection,
  },
  [INDUSTRIES_SECTION_ID]: {
    Component: DynamicIndustriesSection,
  },
  [JOB_TITLES_SECTION_ID]: {
    Component: DynamicJobTitlesSection,
  },
  [RELATED_JOB_TITLES_SECTION_ID]: {
    Component: DynamicJobTitlesSection,
  },
  [SALARY_SECTION_ID]: {
    Component: DynamicSalarySection,
  },
  [RESOURCES_SECTION_ID]: {
    Component: DynamicResourcesSection,
  },
  [RELATED_RESOURCES_SECTION_ID]: {
    Component: DynamicRelatedResourcesSection,
  },
  [SUPPORT_SECTION_ID]: {
    Component: DynamicSupportSection,
  },
  [RESUME_EXAMPLES_SECTION_ID]: {
    Component: DynamicResumeExamplesSection,
  },
  [TESTIMONIAL_SECTION_ID]: {
    Component: DynamicTestimonialSection,
  },
};

export default CONTENT_PAGE_SECTIONS;
