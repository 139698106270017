import React, { useMemo } from 'react';

import {
  BEST_ADVERB_WORD,
  FAST_ADJECTIVE_WORD,
  FAST_ADVERB_WORD,
  GREAT_ADVERB_WORD,
  PERFECT_ADJECTIVE_WORD,
  PROFESSIONAL_ADJECTIVE_WORD,
  SIMPLE_ADJECTIVE_WORD,
  SMART_ADVERB_WORD,
} from '@glass/common/modules/content/staticVariations/adjectiveWords/adjectiveWordVariations';
import { SPACE_STRING } from '@glass/common/modules/strings/constants';
import shuffleSeed from '@glass/common/modules/utils/shuffleSeed';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import RotatingText from '@glass/shared/components/RotatingText/RotatingText';
import usePresentation from '@glass/shared/components/hooks/usePresentation';
import useAdjectiveWords from '@glass/shared/modules/content/staticVariations/adjectiveWords/useAdjectiveWords';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import usePageTerms from '@glass/shared/modules/content/staticVariations/usePageTerms';
import { DAILY_SEED } from '@glass/shared/modules/random/seeds';

// note: do not use 'easy' in here - Steve
// todo: move to server
const rotatingTexts = shuffleSeed.shuffle(
  [
    PERFECT_ADJECTIVE_WORD,
    SIMPLE_ADJECTIVE_WORD,
    PROFESSIONAL_ADJECTIVE_WORD,
    FAST_ADVERB_WORD,
    SMART_ADVERB_WORD,
    BEST_ADVERB_WORD,
    GREAT_ADVERB_WORD,
    'exceptional',
    'in minutes',
    'superior',
    'amazing',
    'outstanding',
    'effortlessly',
    'powerful',
    'memorable',
    'quickly',
  ],
  DAILY_SEED,
);

const useStyles = makeStyles()((theme) => ({
  highlight: {
    color: theme.palette.accent[300],
    fontWeight: theme.typography.fontWeightMedium,
  },
  rotatingWord: {
    color: theme.palette.accent[300],
  },
}));

function DefaultHeroSubtitle() {
  const { classes } = useStyles();
  const adjectiveWords = useAdjectiveWords();
  const resumeWords = useResumeWords();
  const isPresentation = usePresentation();
  const { pageTerm } = usePageTerms();

  const rotatingItems = useMemo(() => {
    if (adjectiveWords.singular !== FAST_ADJECTIVE_WORD) {
      const singularIndex = rotatingTexts.indexOf(adjectiveWords.singular);
      const idx = singularIndex > -1 ? singularIndex : rotatingTexts.indexOf(adjectiveWords.adverb);
      if (idx > -1) {
        const withOut = [...rotatingTexts.slice(0, idx), ...rotatingTexts.slice(idx + 1)];
        return [withOut[0], rotatingTexts[idx], ...withOut.slice(1)];
      }
    }
    return rotatingTexts;
  }, [adjectiveWords.adverb, adjectiveWords.singular]);

  return (
    <div>
      {pageTerm ? (
        <span>
          <span className={classes.highlight}>{pageTerm}</span> {resumeWords.plural},{SPACE_STRING}
        </span>
      ) : (
        <span>
          Your <span className={classes.highlight}>{resumeWords.singular}</span>,{SPACE_STRING}
        </span>
      )}
      <span>made </span>
      <RotatingText
        className={classes.rotatingWord}
        cursor={!isPresentation}
        isStatic={isPresentation}
        items={rotatingItems}
      />
    </div>
  );
}

export default React.memo(DefaultHeroSubtitle);
