import PropTypes from 'prop-types';
import React from 'react';

import goldenRatio from '@glass/common/modules/goldenRatio/goldenRatio';
import Container from '@glass/web/components/Layout/Container';
import { SM } from '@glass/web/modules/theme/breakpointKeys';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

import PaperSearchBar from '@glass/shared/components/Search/PaperSearchBar';
import Section from '@glass/shared/components/Section/Section';
import SectionSpacer, { BOTTOM_EDGE } from '@glass/shared/components/Section/SectionSpacer';

const SearchSection = React.forwardRef(
  (
    {
      onSearchSuccess,
      title,
      titleVariant,
      titleComponent,
      subtitle,
      subtitleVariant,
      subtitleComponent,
      children,
      isAlternatingColor,
      disableCta,
      type,
      outerSpacing,
      spacing,
      align,
      ctaText,
    },
    ref,
  ) => {
    const innerSpacing = spacing / goldenRatio;
    const search = (
      <PaperSearchBar
        disableElevation
        inputProps={{
          id: `${title}-search`,
        }}
        onSearchSuccess={onSearchSuccess}
      />
    );
    return (
      <Section
        ref={ref}
        align={align}
        ctaText={ctaText}
        disableCta={disableCta}
        isAlternatingColor={isAlternatingColor}
        outerSpacing={outerSpacing}
        spacing={spacing}
        subtitle={subtitle}
        subtitleComponent={subtitleComponent}
        subtitleVariant={subtitleVariant}
        title={title}
        titleComponent={titleComponent}
        titleVariant={titleVariant}
        type={type}
      >
        <SectionSpacer edge={!children ? BOTTOM_EDGE : null} spacing={innerSpacing}>
          <Container isNoXPadding isNoYPadding maxWidth={SM}>
            {search}
          </Container>
        </SectionSpacer>
        {children ? (
          <SectionSpacer edge={disableCta ? BOTTOM_EDGE : null} spacing={innerSpacing}>
            {children}
          </SectionSpacer>
        ) : null}
      </Section>
    );
  },
);

SearchSection.defaultProps = {
  spacing: DEFAULT_SPACING,
};

SearchSection.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  ctaText: PropTypes.string,
  disableCta: PropTypes.bool,
  isAlternatingColor: PropTypes.bool,
  onSearchSuccess: PropTypes.func,
  outerSpacing: PropTypes.number,
  spacing: PropTypes.number,
  subtitle: PropTypes.string,
  subtitleComponent: PropTypes.elementType,
  subtitleVariant: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.elementType,
  titleVariant: PropTypes.string,
  type: PropTypes.string,
};

SearchSection.displayName = 'SearchSection';

export default React.memo(SearchSection);
