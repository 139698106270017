import PropTypes from 'prop-types';
import React from 'react';

import Container from '@glass/web/components/Layout/Container';
import SafeHtml from '@glass/web/components/SafeHtml/SafeHtml';
import Typography from '@glass/web/components/Typography/Typography';
import { DEFAULT_LANDING_PAGE_HERO_TITLE } from '@glass/web/modules/content/titles';
import { LG } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER } from '@glass/web/modules/theme/constants';
import { EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

import Breadcrumbs from '@glass/shared/components/Breadcrumbs/Breadcrumbs';
import Link from '@glass/shared/components/Link/Link';
import DefaultHeroSubtitle from '@glass/shared/components/Section/HeroSection/DefaultHeroSubtitle';
import SectionSpacer, {
  BOTTOM_EDGE,
  TOP_EDGE,
} from '@glass/shared/components/Section/SectionSpacer';
import usePresentation from '@glass/shared/components/hooks/usePresentation';
import useExtendText from '@glass/shared/modules/content/useExtendText';
import usePageData from '@glass/shared/modules/pageData/usePageData';

function HeroTitleLayout({
  subtitle: subtitleProp,
  mainCta,
  title,
  titleVariant,
  subtitleVariant,
  titleComponent,
  subtitleComponent,
  titleClassName,
  subtitleClassName,
  align,
  ctaEdge,
}) {
  const { breadcrumbItems, heroTitle } = usePageData();
  const subtitle = typeof subtitleProp !== 'undefined' ? subtitleProp : <DefaultHeroSubtitle />;
  const extendedTitle = useExtendText(title ?? heroTitle ?? DEFAULT_LANDING_PAGE_HERO_TITLE);
  const extendedSubtitle = useExtendText(subtitle);
  const isPresentation = usePresentation();

  return (
    <>
      {!isPresentation && breadcrumbItems?.length ? (
        <Breadcrumbs
          gutterBottom
          isPageTitle
          align={align}
          items={breadcrumbItems}
          lastVariant="h1"
          linkComponent={Link}
        />
      ) : null}
      <Container isNoXPadding maxWidth={LG}>
        {isPresentation || (!breadcrumbItems?.length && extendedTitle) ? (
          <Typography
            align={isPresentation ? CENTER : align}
            className={titleClassName}
            color="inherit"
            component={titleComponent}
            gutterBottom={!!(extendedSubtitle || mainCta)}
            variant={titleVariant}
          >
            <SafeHtml>{extendedTitle}</SafeHtml>
          </Typography>
        ) : null}
        {extendedSubtitle ? (
          <Typography
            gutterBottom
            align={align}
            className={subtitleClassName}
            color="inherit"
            component={subtitleComponent}
            variant={subtitleVariant}
          >
            <SafeHtml>{extendedSubtitle}</SafeHtml>
          </Typography>
        ) : null}
        {mainCta ? (
          <SectionSpacer align={align} edge={ctaEdge} spacing={EXTRA_SMALL_SPACING}>
            {mainCta}
          </SectionSpacer>
        ) : null}
      </Container>
    </>
  );
}

HeroTitleLayout.propTypes = {
  align: PropTypes.string,
  ctaEdge: PropTypes.oneOf([BOTTOM_EDGE, TOP_EDGE, null]),
  mainCta: PropTypes.node,
  subtitle: PropTypes.node,
  subtitleClassName: PropTypes.string,
  subtitleComponent: PropTypes.elementType,
  subtitleVariant: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  titleComponent: PropTypes.elementType,
  titleVariant: PropTypes.string,
};

HeroTitleLayout.defaultProps = {
  titleComponent: 'h1',
  subtitleComponent: 'h2',
  ctaEdge: BOTTOM_EDGE,
};

export default React.memo(HeroTitleLayout);
