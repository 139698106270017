import {
  BASE_PATH_2_QUERY,
  TEMPLATE_QUERY,
  TEMPLATE_VIEW_QUERY,
} from '@glass/common/modules/pages/queryParams';
import BuildRounded from '@glass/web/components/base/icons/BuildRounded';
import DashboardRounded from '@glass/web/components/base/icons/DashboardRounded';
import DoneAllRounded from '@glass/web/components/base/icons/DoneAllRounded';
import WorkRounded from '@glass/web/components/base/icons/WorkRounded';
import { CONTACT_SECTION } from '@glass/web/modules/resumes/router/sections';

const TEN_MINUTES_HWGYH_KEY = 'TEN_MINUTES_HWGYH_KEY';
const TWO_MILLION_HWGYH_KEY = 'TWO_MILLION_HWGYH_KEY';
const RECRUITER_APPROVED_HWGYH_KEY = 'RECRUITER_APPROVED_HWGYH_KEY';
const BUILD_FASTER_HWGYH_KEY = 'BUILD_FASTER_HWGYH_KEY';

export const DEFAULT_HWGYH_SECTION_ORDER = [
  TEN_MINUTES_HWGYH_KEY,
  RECRUITER_APPROVED_HWGYH_KEY,
  TWO_MILLION_HWGYH_KEY,
  BUILD_FASTER_HWGYH_KEY,
];

const HWGYH_SECTIONS = {
  [TEN_MINUTES_HWGYH_KEY]: {
    title: '<b>10 minutes</b> to $(verbWords.singular) your $(resumeWords.singular)',
    subtitle:
      'Our smart tools make $(verbWords.wording) a polished $(resumeWords.singular) faster, so you can concentrate on landing that dream job',
    icon: BuildRounded,
    params: {
      [BASE_PATH_2_QUERY]: CONTACT_SECTION,
    },
  },
  [TWO_MILLION_HWGYH_KEY]: {
    title: 'Over <b>2 million</b> $(resumeWords.abbreviated) templates',
    subtitle:
      'Grab an existing template for your industry, or customize one so its just right for you',
    icon: DashboardRounded,
    params: {
      [TEMPLATE_VIEW_QUERY]: TEMPLATE_QUERY,
    },
  },
  [RECRUITER_APPROVED_HWGYH_KEY]: {
    title: 'Use <b>recruiter-approved</b> bullet points',
    subtitle:
      "We'll suggest pre-written industry-specific text specifically aligned to every section of your $(resumeWords.singular)",
    icon: DoneAllRounded,
  },
  [BUILD_FASTER_HWGYH_KEY]: {
    title: '$(verbWords.capitalized) your $(resumeWords.abbreviated), <b>get hired faster</b>',
    subtitle: 'Download your $(resumeWords.singular) and share it directly with hiring managers',
    icon: WorkRounded,
    params: {
      [BASE_PATH_2_QUERY]: CONTACT_SECTION,
    },
  },
};

export default HWGYH_SECTIONS;
