import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const OG_IMAGE = 'og:image';
const OG_IMAGE_WIDTH = 'og:image:width';
const OG_IMAGE_HEIGHT = 'og:image:height';
const OG_IMAGE_ALT = 'og:image:alt';

function OgImageHead({ src, alt, width, height }) {
  if (!src) {
    return null;
  }
  return (
    <Head>
      <meta key={OG_IMAGE} content={src} property={OG_IMAGE} />
      {alt ? <meta key={OG_IMAGE_ALT} content={alt} property={OG_IMAGE_ALT} /> : null}
      {width ? <meta key={OG_IMAGE_WIDTH} content={width} property={OG_IMAGE_WIDTH} /> : null}
      {height ? <meta key={OG_IMAGE_HEIGHT} content={height} property={OG_IMAGE_HEIGHT} /> : null}
    </Head>
  );
}

OgImageHead.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string,
  width: PropTypes.number,
};

export default React.memo(OgImageHead);
