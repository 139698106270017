import PropTypes from 'prop-types';
import React from 'react';

import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import CardSubSection from '@glass/shared/components/Section/HowWeGetYouHiredSection/CardSubSection';
import HWGYH_SECTIONS from '@glass/shared/components/Section/HowWeGetYouHiredSection/HWGYHSections';
import useExtendText from '@glass/shared/modules/content/useExtendText';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

function SectionItem({ sectionId, bgcolor }) {
  const { title, subtitle, icon, params } = HWGYH_SECTIONS[sectionId] || {};
  const extendedTitle = useExtendText(title);
  const extendedSubTitle = useExtendText(subtitle);

  if (!HWGYH_SECTIONS[sectionId]) {
    return null;
  }

  return (
    <CardSubSection
      bgcolor={bgcolor}
      component={ResumeBuilderLink}
      icon={icon}
      params={params}
      subtitle={extendedSubTitle}
      title={extendedTitle}
      trackProps={{ sectionId }}
      variant="outlined"
    />
  );
}

SectionItem.propTypes = {
  bgcolor: PropTypes.string,
  sectionId: PropTypes.string.isRequired,
};

export default withTrackingContext(SectionItem, 'section-item');
