import PropTypes from 'prop-types';
import React from 'react';

import {
  imageSizes,
  FULL_CAROUSEL_BREAKPOINT_PROPS,
  DEFAULT_BREAKPOINT_PROPS,
} from '@glass/web/components/Carousel/responsive';
import { HERO_CAROUSEL_SECTION_ID } from '@glass/web/modules/content/pages/contentIds';
import { SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

import ResumeCarousel from '@glass/shared/components/ResumeCarousel/ResumeCarousel';
import HeroSectionLayout from '@glass/shared/components/Section/HeroSection/HeroSectionLayout';
import Section from '@glass/shared/components/Section/Section';
import usePresentation from '@glass/shared/components/hooks/usePresentation';

const useStyles = makeStyles()((theme) => ({
  extendedTopSection: {
    marginBottom: theme.spacing(-DEFAULT_SPACING),
  },
  extendedBottomSection: {
    marginTop: theme.spacing(DEFAULT_SPACING),
  },
  carouselContainer: {
    minWidth: 280,
    marginLeft: 'auto',
    [theme.breakpoints.down(SM)]: {
      marginRight: 'auto',
    },
  },
}));

const HeroCarouselSection = React.forwardRef(
  (
    {
      title,
      titleVariant,
      titleClassName,
      subtitle,
      subtitleVariant,
      fallbackId,
      seedOverride,
      contentResumeSlug,
      includeSubheader,
      ctaColor,
      ctaLeftIcon,
      align,
      fullCarousel,
      includeLabels,
      extendedSectionProps,
      carouselLabelColor,
    },
    ref,
  ) => {
    const breakpointProps = fullCarousel
      ? FULL_CAROUSEL_BREAKPOINT_PROPS
      : DEFAULT_BREAKPOINT_PROPS;
    const { classes } = useStyles();
    const isPresentation = usePresentation();

    return (
      <>
        <HeroSectionLayout
          ref={ref}
          align={align}
          childrenMdBreakpoint={fullCarousel ? 12 : 7}
          childrenSmBreakpoint={fullCarousel ? 12 : 7}
          containerClassName={extendedSectionProps ? classes.extendedTopSection : undefined}
          ctaColor={ctaColor}
          ctaEdge={fullCarousel ? null : 'bottom'}
          ctaLeftIcon={ctaLeftIcon}
          id={HERO_CAROUSEL_SECTION_ID}
          isNoBottomPadding={!!extendedSectionProps}
          seedOverride={seedOverride}
          subtitle={subtitle}
          subtitleVariant={subtitleVariant}
          title={title}
          titleClassName={titleClassName}
          titleVariant={titleVariant}
        >
          <div className={classes.carouselContainer}>
            <ResumeCarousel
              priorityImages
              breakpointProps={breakpointProps}
              contentResumeSlug={contentResumeSlug}
              fallbackId={fallbackId}
              hasButtons={!isPresentation}
              hasLabels={includeLabels}
              hasSubheader={includeSubheader}
              imageSizes={fullCarousel ? imageSizes : '$down(xs) 70vw, $between(sm,lg) 28vw, 430px'}
              labelColor={carouselLabelColor}
              origin="hero"
              seedOverride={seedOverride}
              shouldAutoPlay={!isPresentation}
            />
          </div>
        </HeroSectionLayout>
        {extendedSectionProps ? (
          <Section
            {...extendedSectionProps}
            className={classes.extendedBottomSection}
            id="extended-hero"
          />
        ) : null}
      </>
    );
  },
);

HeroCarouselSection.propTypes = {
  align: PropTypes.string,
  carouselLabelColor: PropTypes.string,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  contentResumeSlug: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaLeftIcon: PropTypes.node,
  extendedSectionProps: PropTypes.shape(Section.propTypes),
  fallbackId: PropTypes.string,
  fullCarousel: PropTypes.bool,
  includeLabels: PropTypes.bool,
  includeSubheader: PropTypes.bool,
  seedOverride: PropTypes.string,
  spacing: PropTypes.number,
  subtitle: PropTypes.node,
  subtitleVariant: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleClassName: PropTypes.string,
  titleVariant: PropTypes.string,
};

HeroCarouselSection.defaultProps = {
  titleVariant: 'h1',
  subtitleVariant: 'h2',
  includeSubheader: true,
  contentResumeSlug: 'default',
};

HeroCarouselSection.displayName = 'HeroCarouselSection';

export default React.memo(HeroCarouselSection);
