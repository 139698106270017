import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import CONTENT_PAGE_SECTIONS from '@glass/shared/modules/content/pages/contentPageSections';

function RenderContentPageComponent({ data }) {
  const { type, hidden, props: sectionProps } = data;
  const { Component } = CONTENT_PAGE_SECTIONS[type] || {};

  if (!Component) {
    throw new Error(`No valid component for: ${type}`);
  }

  const props = useMemo(
    () =>
      removeEmptyKeys({
        ...(sectionProps || {}),
        ...data,
      }),
    [data, sectionProps],
  );

  if (hidden) {
    return null;
  }

  return <Component {...sectionProps} {...props} />;
}

RenderContentPageComponent.propTypes = {
  data: PropTypes.shape({
    hidden: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.object,
    type: PropTypes.string.isRequired,
  }),
  hidden: PropTypes.bool,
  timeoutDelay: PropTypes.number,
  type: PropTypes.string.isRequired,
};

RenderContentPageComponent.displayName = 'RenderContentPageComponent';

export default React.memo(RenderContentPageComponent);
