import React, { forwardRef, Ref } from 'react';

import IconButton, { type IconButtonProps } from '@glass/web/components/base/IconButton';
import Tooltip, { type TooltipProps } from '@glass/web/components/base/Tooltip';

interface IconButtonTooltipProps {
  action: string;
  color?: IconButtonProps['color'];
  edge?: IconButtonProps['edge'];
  className?: string;
  placement?: TooltipProps['placement'];
  onClick?: IconButtonProps['onClick'];
  children?: IconButtonProps['children'];
  disabled?: IconButtonProps['disabled'];
}

const IconButtonTooltip = forwardRef(
  (
    {
      action,
      color,
      className,
      placement,
      edge,
      onClick,
      children,
      disabled,
    }: IconButtonTooltipProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <div className={className}>
        <Tooltip disableInteractive placement={placement} title={action}>
          <div>
            <IconButton
              ref={ref}
              aria-label={action}
              color={color}
              edge={edge}
              onClick={onClick}
              disabled={disabled}
            >
              {children}
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
  },
);

IconButtonTooltip.displayName = 'IconButtonTooltip';

export default IconButtonTooltip;
