import PropTypes from 'prop-types';
import React from 'react';

import SafeHtml from '@glass/web/components/SafeHtml/SafeHtml';
import Typography from '@glass/web/components/Typography/Typography';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme) => ({
  content: {
    height: 'inherit',
    display: FLEX,
    flexDirection: 'column',
    padding: theme.spacing(EXTRA_SMALL_SPACING / 2, 0),
  },
  icon: {
    margin: theme.spacing(0, 'auto'),
  },
  title: {
    margin: theme.spacing(EXTRA_SMALL_SPACING / 2, 'auto'),
  },
}));

function SubSection({ title, subtitle, align, icon: Icon }) {
  const { classes } = useStyles();
  return (
    <div className={classes.content}>
      {Icon ? <Icon className={classes.icon} fontSize="large" /> : null}
      {title ? (
        <Typography align={align} className={classes.title} gutterBottom={!!subtitle} variant="h4">
          <SafeHtml>{title}</SafeHtml>
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography align={align}>
          <SafeHtml>{subtitle}</SafeHtml>
        </Typography>
      ) : null}
    </div>
  );
}

SubSection.propTypes = {
  align: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

SubSection.defaultProps = {
  align: CENTER,
};

export default SubSection;
