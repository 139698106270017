import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createSlug from '@glass/common/modules/url/createSlug';

import OgImageHead from '@glass/shared/components/Head/OgImageHead';
import { SEO_TITLE_PREFIX_IMAGES } from '@glass/shared/modules/images/constants';
import generateImageSrc from '@glass/shared/modules/images/generateImageSrc';
import generateOgImagePath from '@glass/shared/modules/images/generateOgImagePath';
import ogImageSize from '@glass/shared/modules/images/ogImageSize';

const useAutoImageSrc = ({ path, seoTitle }) => {
  return useMemo(() => {
    if (path) {
      return generateImageSrc({
        seoTitlePrefix: SEO_TITLE_PREFIX_IMAGES,
        seoTitle: createSlug(
          seoTitle || (path?.length > 1 ? path : process.env.NEXT_PUBLIC_BRAND_TITLE),
        ),
        path: generateOgImagePath({ path }),
      });
    }

    return '';
  }, [path, seoTitle]);
};

function AutoOgImageHead({ alt, path, seoTitle }) {
  const { asPath } = useRouter();
  const src = useAutoImageSrc({ path: path || asPath, seoTitle });

  // todo: this is broken with `isReady`, how do we get the path of the current page on the server?
  // if (!isReady || (!path && !asPath)) {
  if (asPath === null || (!path && !asPath)) {
    return null;
  }

  return <OgImageHead alt={alt} height={ogImageSize.height} src={src} width={ogImageSize.width} />;
}

AutoOgImageHead.propTypes = {
  alt: PropTypes.string,
  path: PropTypes.string,
  seoTitle: PropTypes.string,
};

export default React.memo(AutoOgImageHead);
